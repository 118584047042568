<template>
  <jaya-connection-layout text="Renseignez votre ancien mot de passe et choisissez en un nouveau">
    <validation-observer v-slot="{invalid, handleSubmit}">
      <b-form @submit.prevent="handleSubmit(changePassword)">
        <b-row>
          <!-- Password -->
          <b-col cols="12">
            <b-form-group label="Ancien mot de passe">

              <!--Old Password-->
              <validation-provider v-slot="{errors}" immediate rules="required">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="old_password"
                      :type="passwordFieldType"
                      autofocus
                      placeholder="Ancien mot de passe"/>
                </b-input-group>
                <p class="text-danger">{{ errors[0] }}</p>
                <p v-if="passwordErrors.old_password === 'wrongPassword'" class="text-danger">
                  Ancien mot de passe incorrect
                </p>
              </validation-provider>
            </b-form-group>


            <b-form-group label="Nouveau mot de passe">
              <!--New Password-->
              <p class="font-small-2 font-italic">
                Il doit faire plus de 8 caractères, ne pas ressembler à votre nom, prénom ou adresse mail et être
                composé d'au moins un chiffre, une minuscule, une majuscule et un caractère spécial.
              </p>

              <validation-provider v-slot="{errors}" immediate rules="required" vid="new_password">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="new_password"
                      :type="passwordFieldType"
                      placeholder="Nouveau mot de passe"/>
                </b-input-group>
                <p class="text-danger">{{ errors[0] }}</p>
              </validation-provider>

              <!--New Password Confirmation-->
              <validation-provider v-slot="{errors}" immediate rules="required|confirmed:new_password">
                <b-input-group class="mt-50">
                  <b-input-group-prepend is-text>
                    <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="new_password_confirm"
                      :type="passwordFieldType"
                      placeholder="Confirmation"/>
                </b-input-group>
                <p class="text-danger">{{ errors[0] }}</p>
                <p v-if="passwordErrors.password === 'tooShort'"
                    class="text-danger">
                  Votre nouveau mot de passe doit contenir au moins 8 caractères
                  dont une lettre et un chiffre
                </p>
                <p v-if="passwordErrors.password === 'tooCommonPassword'"
                    class="text-danger">
                  Votre mot de passe est trop simple.
                </p>
                <p v-if="passwordErrors.password === 'numeric'"
                    class="text-danger">
                  Votre mot de passe ne peut pas être composé exclusivement de chiffres.
                </p>
                <p v-if="passwordErrors.password === 'tooSimilar'"
                    class="text-danger">
                  Votre mot de passe est trop proche de votre nom d'utilisateur ou email.
                </p>
                <p v-if="passwordErrors.password === 'badPassword'"
                    class="text-danger">
                  Votre mot de passe ne respecte pas notre politique de sécurité, veuillez en essayer un autre, de
                  plus de 8 caractères dont au moins une lettre et un chiffre
                </p>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Buttons-->
          <b-col class="d-flex justify-content-around flex-wrap mb-1" cols="12">
            <b-button
                :disabled="invalid"
                class="m-25 flex-grow-1"
                type="submit"
                variant="primary">
              Changer le mot de passe
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </jaya-connection-layout>
</template>
<script>

import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from "bootstrap-vue";
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {togglePasswordVisibility} from "@core/mixins/ui/forms";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {confirmed, required} from "vee-validate/dist/rules";

extend('required', {...required, message: 'Ce champ est requis'});
extend('confirmed', {...confirmed, message: 'Les mots de passe ne correspondent pas'});

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      old_password: "",
      new_password: "",
      new_password_confirm: "",
      passwordErrors: {}
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    changePassword() {
      let obj = {};
      obj.old_password = this.old_password
      obj.password = this.new_password
      obj.password_confirm = this.new_password_confirm
      this.$store.dispatch("account/change_password", obj)
          .then(() => {
            this.$notify({
              time: 4000,
              title: "Mot de passe",
              text: "Votre mot de passe a bien été changé",
              iconPack: "feather",
              icon: "icon-user-check",
              color: "primary"
            })
            this.$router.push({name: "login"})
          })
          .catch((error) => {
            this.$notify({
              time: 4000,
              title: this.$t('Error'),
              text: "Le changement du mot de passe a échoué",
              color: "danger"
            })
            if (error.response && error.response.status === 400) {
              if (error.response.data) {
                if (error.response.data.old_password) {
                  this.passwordErrors.old_password = "wrongPassword"
                }
                if (error.response.data.password) {
                  this.passwordErrors.password = this.$parsePasswordErrors(error.response.data.password)
                }
              }
            }
          })
    }
  }
};
</script>

<style lang="scss">
</style>
