var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('jaya-connection-layout',{attrs:{"text":"Renseignez votre ancien mot de passe et choisissez en un nouveau"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ancien mot de passe"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"autofocus":"","placeholder":"Ancien mot de passe"},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}})],1),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.passwordErrors.old_password === 'wrongPassword')?_c('p',{staticClass:"text-danger"},[_vm._v(" Ancien mot de passe incorrect ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Nouveau mot de passe"}},[_c('p',{staticClass:"font-small-2 font-italic"},[_vm._v(" Il doit faire plus de 8 caractères, ne pas ressembler à votre nom, prénom ou adresse mail et être composé d'au moins un chiffre, une minuscule, une majuscule et un caractère spécial. ")]),_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"placeholder":"Nouveau mot de passe"},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"immediate":"","rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mt-50"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1),_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"placeholder":"Confirmation"},model:{value:(_vm.new_password_confirm),callback:function ($$v) {_vm.new_password_confirm=$$v},expression:"new_password_confirm"}})],1),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.passwordErrors.password === 'tooShort')?_c('p',{staticClass:"text-danger"},[_vm._v(" Votre nouveau mot de passe doit contenir au moins 8 caractères dont une lettre et un chiffre ")]):_vm._e(),(_vm.passwordErrors.password === 'tooCommonPassword')?_c('p',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe est trop simple. ")]):_vm._e(),(_vm.passwordErrors.password === 'numeric')?_c('p',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe ne peut pas être composé exclusivement de chiffres. ")]):_vm._e(),(_vm.passwordErrors.password === 'tooSimilar')?_c('p',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe est trop proche de votre nom d'utilisateur ou email. ")]):_vm._e(),(_vm.passwordErrors.password === 'badPassword')?_c('p',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe ne respecte pas notre politique de sécurité, veuillez en essayer un autre, de plus de 8 caractères dont au moins une lettre et un chiffre ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-around flex-wrap mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"m-25 flex-grow-1",attrs:{"disabled":invalid,"type":"submit","variant":"primary"}},[_vm._v(" Changer le mot de passe ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }